import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OtisPage = (props) => (
  <Layout>
    <SEO title="Otis Page" />
    <Link className="back-link" to="/">Go back to the homepage</Link>
    <h1 style={{marginTop: `50px`, marginBottom: `50px`, textAlign: 'center'}}>Otis Sass Templates</h1>
    <Img fluid={props.data.imageOtisHeader.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto 0`}}>
        <p>
            I was part of the front-end team that worked on the Otis website. It was my role to contribute to the HTML, Sass and Javascript to sculpt a component driven UI. A vast number of templates, catering for a multitude of devices and screen sizes, were built to bring the UI designs to life.
        </p>
    </div>
    <Img fluid={props.data.imageOtisResponsive.childImageSharp.fluid} />
    <div style={{maxWidth: `700px`, margin: `40px auto`}}>
        <p style={{textAlign: `center`}}>
            Here is an example of how the site resizes. The full site can be viewed below.
        </p>
    </div>
    <div style={{textAlign: `center`}}>
        <a className="button" href="https://www.otis.com" target="_blank">View Site</a>
    </div>
    <h2 style={{marginTop: `80px`}}>View other pieces of work...</h2>
    <div className="work" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`, marginTop: `40px`}}>
        <a href="/quantum/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-1" style={{height: `300px`}}></div>
        </a>
        <a href="/tim-burton/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-2" style={{height: `300px`}}></div>
        </a>
        <a href="/icons/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-4" style={{height: `300px`}}></div>
        </a>
    </div>
    <Link className="back-link" to="/" style={{marginTop: `20px`, display: `block`}}>Go back to the homepage</Link>
  </Layout>
)

export default OtisPage

export const pageQuery = graphql`
  query {
    imageOtisHeader: file(relativePath: { eq: "otis-header.png" }) {
      ...fluidImage
    }
    imageOtisResponsive: file(relativePath: { eq: "otis-responsive.png" }) {
      ...fluidImage
    }
  }
`